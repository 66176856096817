<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("employeeLevel.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')"
            v-model="searchItem"
            @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">{{ $t("employeeLevel.create") }}</v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listEmployeeLevel.length > 0">
          <thead>
          <tr>
            <th class="text-left">{{ $t("employeeLevel.no") }}</th>
            <th class="text-left">{{ $t("employeeLevel.name") }}</th>
            <th class="text-left">{{ $t("employeeLevel.created") }}</th>
            <th class="text-left">{{ $t("employeeLevel.updated") }}</th>
            <th class="text-left"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in listEmployeeLevel" :key="index">
            <td>
              {{ index + 1 }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ formatDate(item.createdAt) }}
            </td>
            <td>
              {{ formatDate(item.updatedAt) }}
            </td>
            <td>

            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                    >Edit
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                    >Delete
                    </v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Loading v-if="isLoading"/>
        <ModalEdit :title="$t('employeeLevel.title')">
          <template v-slot="{ close }">
            <editEmployeeLevel
                @close="close"
                :employeeLevel="employeeLevel"
                @success="fetchEmployeeLevel"
            />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <deleteEmployeeLevel
                @close="close"
                @success="fetchEmployeeLevel"
                :employeeLevelId="employeeLevelId"
            />
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import editEmployeeLevel from "@/components/EmployeeLevel/editEmployeeLevel";
import deleteEmployeeLevel from "@/components/EmployeeLevel/deleteEmployeeLevel";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import moment from "moment";

export default {
  components: {
    editEmployeeLevel,
    deleteEmployeeLevel,
    Loading,
    defaultTableNoResult,
  },
  data() {
    return {
      isLoading: true,
      listEmployeeLevel: [],
      employeeLevel: {},
      employeeLevelId: "",
      searchItem: "",
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YY-MM-DD")
    },
    searchFilterItem() {
      this.fetchEmployeeLevel();
    },
    filterEmployeeLevel(employeeLevelId) {
      return (
          this.listEmployeeLevel.filter((item) => {
            return item.id == employeeLevelId;
          })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({
        name: "employeeLevel.create",
      }).catch(() => {
      });
    },

    onEdit(employeeLevelId) {
      this.employeeLevel = {
        ...this.filterEmployeeLevel(employeeLevelId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(employeeLevelId) {
      this.employeeLevelId = employeeLevelId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchEmployeeLevel() {
      this.isLoading = true;
      this.$axios
          .get(`company/list/employee/levels`, {})
          .then((res) => {
            if (res.status === 200) {
              this.isLoading = false;
              this.listEmployeeLevel = res.data.listLevels;
              console.log(this.listEmployeeLevel)
            }
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
    },
  },
  created() {
    this.fetchEmployeeLevel();
  },
};
</script>

<style scoped lang="scss">
</style>
